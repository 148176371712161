<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent">
        <div class="my-card">
          <van-field
            readonly
            required
            v-model="viewData.ChildrenLeaveTypeName"
            name="ChildrenLeaveTypeName"
            label="请假类型"
            placeholder="请假类型"
            :rules="[{ required: true, message: '请选择请假类型' }]"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              value-key="EnumName"
              :columns="leaveTypeList"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.BeginDate"
            name="BeginDate"
            label="请假开始时间"
            placeholder="请假开始时间"
            :rules="[{ required: true, message: '请选择请假开始时间' }]"
            @click="showTime = true"
          />
          <van-popup v-model="showTime" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              v-model="defaultTime"
              :min-date="minStartTime"
              @confirm="onConfirmTime"
              @cancel="showTime = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.EndDate"
            name="EndDate"
            label="请假结束时间"
            placeholder="请假结束时间"
            :rules="[{ required: true, message: '请选择请假结束时间' }]"
            @click="showTime2 = true"
          />
          <van-popup v-model="showTime2" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              v-model="defaultTime2"
              :min-date="minDate"
              @confirm="onConfirmTime2"
              @cancel="showTime2 = false"
            />
          </van-popup>
          <van-field
            required
            type="number"
            v-model="viewData.LeaveDays"
            name="LeaveDays"
            label="请假天数"
            placeholder="请假天数"
            :rules="[
              { required: true, message: '请填写请假天数' },
              {
                pattern:
                  /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
                message: '请填写正数',
              },
            ]"
          />
          <van-field
            required
            type="number"
            v-model="viewData.LeaveTimeCount"
            name="LeaveTimeCount"
            label="请假总时长"
            placeholder="请假总时长"
            :rules="[
              { required: true, message: '请填写请假总时长' },
              {
                pattern:
                  /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
                message: '请填写正数',
              },
            ]"
          />
          <van-field
            required
            v-model="viewData.LeaveDesc"
            name="LeaveDesc"
            rows="2"
            label="请假事由"
            type="textarea"
            placeholder="请输入请假事由"
            :rules="[{ required: true, message: '请输入请假事由' }]"
          />
          <van-field
            readonly
            required
            v-model="viewData.AuditUserIDListName"
            name="weituo"
            label="审核人"
            placeholder="审核人"
            :rules="[{ required: true, message: '请选择审核人' }]"
            @click="showPicker2 = true"
          />
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker
              show-toolbar
              value-key="Name"
              :columns="pepoleList1"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
            />
          </van-popup>
          <van-field
          type="textarea"
            readonly
            v-model="viewData.CopyUserIDListName"
            name="weituo"
            label="抄送老师"
            placeholder="抄送老师"
            :rules="[{ required: false, message: '请选择抄送老师' }]"
            @click="showPicker3 = true"
          >
            <!-- <template #button>
              <van-icon size="22px" name="close" @click.stop="clearPeople2" />
            </template> -->
          </van-field>
          <van-popup v-model="showPicker3" position="bottom">
            <van-picker
              show-toolbar
              value-key="Name"
              :columns="pepoleList2"
              @confirm="onConfirm3"
              @cancel="showPicker3 = false"
            />
          </van-popup>
          <van-field name="AccessoryPostList" label="附件">
            <template #input>
              <van-uploader
                v-model="viewData.AccessoryPostList"
                :deletable="true"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
          <!-- 审核签名 -->
          <div
            class="van-cell van-cell--required van-field"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div>
              <span>家长签名</span>
            </div>
            <div
              class=""
              style="color: #00aaff"
              @click="enterSign"
              v-if="Base64Img"
            >
              <van-icon
                name="edit"
                size="18px"
                style="position: relative; right: 8px"
              />
              <span>重签</span>
            </div>
            <van-icon
              name="edit"
              @click="enterSign"
              size="18px"
              style="position: relative; right: 8px"
              v-else
            />
          </div>
          <div class="signImg" v-if="Base64Img">
            <img :src="Base64Img" style="width: 40%; height: 100px" />
          </div>
          <van-checkbox
            v-model="viewData.IsContinued"
            style="padding: 0 10px; margin-top: 10px"
            >下次继续使用该签名
          </van-checkbox>
        </div>
        <van-button
          :loading="loading"
          block
          color="#47AFA7"
          type="info"
          native-type="submit"
          :disabled="disabled"
          >提交</van-button
        >
      </van-form>
    </div>

    <!-- 底部弹窗 -->
    <writeSign ref="writeSign"></writeSign>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Icon,
  Toast,
  CheckboxGroup,
  Checkbox,
} from "vant";

import writeSign from "../../writeSign/index.vue";

export default {
  components: {
    writeSign,
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
  },
  computed: {
    minDate: function () {
      let date = this.defaultTime;
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() + 1
      );
    },
  },
  data() {
    return {
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      id: null,
      viewData: {
        AccessoryPostList: [],
        CopyUserIDListName: "",
        IsContinued: false,
        Base64Img: "",
      },
      leaveTypeList: [],
      showPicker: false,
      showPicker2: false,
      showTime: false,
      showTime2: false,
      showPicker3: false,
      loading: false,
      pepoleList1: [],
      pepoleList2: [],
      defaultTime: new Date(),
      defaultTime2: new Date(),
      minStartTime: new Date(
        new Date().getFullYear(),
        0,
        1
      ),
      Base64Img: "", //展示判断使用
      disabled:false
    };
  },
  mounted() {
    this.getEnum();
    this.getPickList();
    this.getSigh();
  },
  methods: {
    getEnum() {
      this.$axios
        .get("/api/Base/GetEnum?type=ChildrenLeaveType")
        .then((res) => {
          if (res.code === 200) {
            this.leaveTypeList = res.data.List;
          }
        });
    },
    // 手写页面弹窗
    enterSign() {
      this.$refs.writeSign.show = true;
    },
    // 获取电子签名
    getSigh() {
      this.$axios.get("/api/User/GetSign").then((res) => {
        if (res.code === 200) {
          // 编辑回显赋值（已用的签名数据）
          this.viewData.IsContinued = res.data.IsContinued;
          this.Base64Img = res.data.IsContinued ? res.data.UserSign : "";
        } else {
          this.$toast.fail(res.msg || "请求异常");
          return false;
        }
      });
    },
    getPickList() {
      this.$axios
        .get("/api/Class/GetByClass", {
          classID: this.userInfo.OpenChildren.ClassID,
        })
        .then((res) => {
          if (res.code === 200) {
            // 1 委托，2抄送人
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].LeaveForChildrenType === 1) {
                this.pepoleList1.push(res.data[i]);
              } else if (res.data[i].LeaveForChildrenType === 2) {
                this.pepoleList2.push(res.data[i]);
                if(res.data[i].IsSelect){
                  res.data[i].disabled = true
                  const arrName = this.viewData.CopyUserIDListName
                    ? this.viewData.CopyUserIDListName.split(",")
                    : [];
                  const arrID = this.viewData.CopyUserIDList
                    ? this.viewData.CopyUserIDList
                    : [];
                  if (arrName.indexOf(res.data[i].Name) === -1) {
                    arrName.push(res.data[i].Name);
                    arrID.push(res.data[i].ID);
                  } else {
                    arrID.splice(arrName.indexOf(res.data[i].Name), 1);
                    arrName.splice(arrName.indexOf(res.data[i].Name), 1);
                  }
                  this.viewData.CopyUserIDListName = arrName.join(",");
                  this.viewData.CopyUserIDList = arrID;
                }
              }
            }
          }
        });
    },
    onConfirm(value) {
      this.viewData.ChildrenLeaveTypeName = value.EnumName;
      this.viewData.ChildrenLeaveType = value.EnumValue;
      this.showPicker = false;
    },
    onConfirm2(value) {
      this.viewData.AuditUserIDList = [value.ID];
      this.viewData.AuditUserIDListName = value.Name;
      this.showPicker2 = false;
    },
    onConfirm3(value) {
      const arrName = this.viewData.CopyUserIDListName
        ? this.viewData.CopyUserIDListName.split(",")
        : [];
      const arrID = this.viewData.CopyUserIDList
        ? this.viewData.CopyUserIDList
        : [];
      if (arrName.indexOf(value.Name) === -1) {
        arrName.push(value.Name);
        arrID.push(value.ID);
      } else {
        arrID.splice(arrName.indexOf(value.Name), 1);
        arrName.splice(arrName.indexOf(value.Name), 1);
      }
      this.viewData.CopyUserIDListName = arrName.join(",");
      this.viewData.CopyUserIDList = arrID;
      console.log(arrName);
      console.log(arrID);
      // if (this.viewData.CopyUserIDListName) {
      //   this.viewData.CopyUserIDListName =
      //     this.viewData.CopyUserIDListName + ',' + value.Name
      // } else {
      //   this.viewData.CopyUserIDListName = value.Name
      // }
      this.showPicker3 = false;
    },
    onConfirmTime(value) {
      this.viewData.BeginDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime = false;
      // 计算总时长
      this.sumTime();
    },
    onConfirmTime2(value) {
      this.viewData.EndDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime2 = false;
      // 计算总时长
      this.sumTime();
    },
    sumTime() {
      const startTime = this.viewData.BeginDate;
      const endTime = this.viewData.EndDate;
      if (startTime && endTime) {
        this.$set(
          this.viewData,
          "LeaveTimeCount",
          this.$moment(endTime)
            .diff(this.$moment(startTime), "hours", true)
            .toFixed(1)
        );
        // this.viewData.LeaveTimeCount = this.$moment(endTime)
        //   .diff(this.$moment(startTime), 'hours', true)
        //   .toFixed(1)
      }
    },
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            let that = this;
            Toast.loading({
              message: "上传中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);

            that.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                //console.log(res)
                that.$toast.success(res.msg);
                that.viewData.AccessoryPostList.push({
                  name: file.name,
                  osskey: res.data.osskey,
                  uploadUrl: res.data.uploadUrl,
                  url: res.data.url,
                });
              } else {
                that.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        let that = this;
        Toast.loading({
          message: "上传中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);

        that.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            //console.log(res)
            that.$toast.success(res.msg);
            that.viewData.AccessoryPostList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url,
            });
          } else {
            that.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    save() {
      this.disabled = true;
      let that = this;
      if (!this.Base64Img) {
        this.disabled = false;
        return this.$toast.fail("家长签名不能为空");
      }
      // 判断是否重签
      // 不存在http,重签
      if (this.Base64Img.indexOf("http") == -1) {
        this.viewData.ParentSign = "";
        this.viewData.Base64Img = this.Base64Img;
      } else {
        // 未重签,直接赋值http到ParentSign
        this.viewData.ParentSign = this.Base64Img;
        this.viewData.Base64Img = "";
      }
      this.$axios
        .post("/api/LeaveForChildren/Add", this.viewData)
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.msg || "操作成功");
            this.disabled = false;
            // 跳转到请假记录
            this.$emit("addClick");
          } else {
            this.disabled = false;
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    clearPeople2() {
      // this.viewData.CopyUserIDListName = ''
      this.$set(this.viewData, "CopyUserIDListName", "");
      this.$set(this.viewData, "CopyUserIDList", []);
    },
  },
};
</script>
<style lang="less" scoped>
.signImg {
  padding: 0 10px;
  margin-top: 10px;
}

@import "./index.less";
</style>
